
<div v-if="Workspace" class="general">
  <div v-if="blob" class="general-cropper">
    <image-cropper
      :blob="blob"
      isSquare
      :submitButtonLabel="$t('save')"
      :cancelButtonLabel="$t('cancel')"
      :imageWidth="cropperMaxWidth"
      :imageHeight="cropperMaxHeight"
      @cropImage="cropImage"
    />
  </div>
  <form v-if="!blob" class="general-form">
    <div class="general-form-section">
      <div class="general-form-section-title">{{ $t('workspaceStorage') }}</div>
      <div class="general-form-section-description">{{ $t('manageStorage') }}</div>
      <div class="general-form-section-progressbar">
        <div class="general-form-section-progressbar-bar">
          <div class="general-form-section-progressbar-bar-containers">
            <progress-bar
              class="progress-bar"
              :helper="getWorkspaceTracksCount"
              :percent="progressBarTracksValue"
              :color="progressBarTracksColor"
              :size="520"
            />
            <progress-bar
              class="progress-bar"
              :helper="$t('storageUsed', { used: spaceUsedInOctets, total: totalSpaceInOctets })"
              :percent="progressBarStorageValue"
              :color="progressBarStorageColor"
              :size="520"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="general-form-section">
      <div class="general-form-section--">
        <div class="general-form-section-title">{{ $t('workspaceInformation') }}</div>
        <div class="general-form-section-description">{{ $t('workspaceDescription') }}</div>
        <div class="general-form-section-content">
          <div class="general-form-section-content-avatar">
            <image-browser
              :imgUrl="imgUrl"
              @changeImgUrl="changeImgUrl"
              pictureSize="large"
              square
            />
          </div>
          <div class="general-form-section-content-textfield">
            <text-field
              :key="`wname-${randomKey}`"
              inputType="text"
              :errorMessage="nameValidationMessage"
              :fieldModel.sync="$v.name.$model"
              :value="currentWorkspace.name"
              placeholder
              :helper="$t('maxChars', { nbChars: $v.name.$params.maxLength.max })"
              :label="$t('workspaceName')"
              :disabled="!isWorkspaceAdmin"
              @handleInput="handleNameChange"
            />
          </div>
        </div>
        <div class="general-form-section-button">
          <submit-button
            :label="$t('save')"
            :disabled="nameDisableStatus"
            :pending="nameSubmitStatus === 'PENDING'"
            @submit="updateWorkspace"
          />
        </div>
      </div>
      <div
        class="general-form-section--"
        v-if="this.WorkspaceHubs && this.WorkspaceHubs.length > 0"
        id="hubs"
      >
        <div class="general-form-section-title">{{ $t('hubsInformation') }}</div>
        <div class="general-form-section-description">
          {{ $t('hubsDescription.first') }}
          <div>{{ $t('hubsDescription.second') }}</div>
        </div>
        <div
          class="general-form-section-content-textfield"
          v-for="(workspaceHub, index) in this.WorkspaceHubs"
          :key="`hub-${index}-${commentSubmitStatus[workspaceHub.hub_id]}`"
          ref="hubs"
        >
          <div class="general-form-section-content-title">
            {{ workspaceHub.hub.name }}
          </div>
          <text-area
            :fieldModel.sync="$v.comment.$model[workspaceHub.hub_id]"
            :placeholder="$t('hubSettingsPlaceholder')"
            :errorMessage="getCommentValidationMessage(workspaceHub.hub_id)"
            :value="workspaceHub.comment"
            ref="comment"
            @handleInput="handleCommentChange(workspaceHub.hub_id)"
            :isForCommentSetting="true"
            class="general-form-section-content-textarea"
          />
          <div class="general-form-section-button">
            <submit-button
              :label="$t('save')"
              :disabled="disableCommentSaveButton(workspaceHub.hub_id)"
              :pending="commentSubmitStatus[workspaceHub.hub_id] === 'PENDING'"
              @submit="saveHubComment(workspaceHub.hub_id)"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      class="general-form-section"
      v-if="this.Workspace.owner_id === this.currentWorkspace.owner_id"
    >
      <div class="general-form-section-title">{{ $t('deleteWorkspace.sectionTitle') }}</div>
      <div class="general-form-section-content-title">{{ $t('deleteWorkspace.title') }}</div>
      <div class="general-form-section-content-text">{{ $t('deleteWorkspace.text') }}</div>
      <div class="general-form-section-button">
        <submit-button
          :label="$t('deleteWorkspace.title')"
          size="large"
          btnStyle="secondary"
          danger
          @submit="openDeleteWorkspaceModal"
        />
      </div>
    </div>
  </form>
</div>
