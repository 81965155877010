<template>
  <div v-if="Workspace" class="general">
    <div v-if="blob" class="general-cropper">
      <image-cropper
        :blob="blob"
        isSquare
        :submitButtonLabel="$t('save')"
        :cancelButtonLabel="$t('cancel')"
        :imageWidth="cropperMaxWidth"
        :imageHeight="cropperMaxHeight"
        @cropImage="cropImage"
      />
    </div>
    <form v-if="!blob" class="general-form">
      <div class="general-form-section">
        <div class="general-form-section-title">{{ $t('workspaceStorage') }}</div>
        <div class="general-form-section-description">{{ $t('manageStorage') }}</div>
        <div class="general-form-section-progressbar">
          <div class="general-form-section-progressbar-bar">
            <div class="general-form-section-progressbar-bar-containers">
              <progress-bar
                class="progress-bar"
                :helper="getWorkspaceTracksCount"
                :percent="progressBarTracksValue"
                :color="progressBarTracksColor"
                :size="520"
              />
              <progress-bar
                class="progress-bar"
                :helper="$t('storageUsed', { used: spaceUsedInOctets, total: totalSpaceInOctets })"
                :percent="progressBarStorageValue"
                :color="progressBarStorageColor"
                :size="520"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="general-form-section">
        <div class="general-form-section--">
          <div class="general-form-section-title">{{ $t('workspaceInformation') }}</div>
          <div class="general-form-section-description">{{ $t('workspaceDescription') }}</div>
          <div class="general-form-section-content">
            <div class="general-form-section-content-avatar">
              <image-browser
                :imgUrl="imgUrl"
                @changeImgUrl="changeImgUrl"
                pictureSize="large"
                square
              />
            </div>
            <div class="general-form-section-content-textfield">
              <text-field
                :key="`wname-${randomKey}`"
                inputType="text"
                :errorMessage="nameValidationMessage"
                :fieldModel.sync="$v.name.$model"
                :value="currentWorkspace.name"
                placeholder
                :helper="$t('maxChars', { nbChars: $v.name.$params.maxLength.max })"
                :label="$t('workspaceName')"
                :disabled="!isWorkspaceAdmin"
                @handleInput="handleNameChange"
              />
            </div>
          </div>
          <div class="general-form-section-button">
            <submit-button
              :label="$t('save')"
              :disabled="nameDisableStatus"
              :pending="nameSubmitStatus === 'PENDING'"
              @submit="updateWorkspace"
            />
          </div>
        </div>
        <div
          class="general-form-section--"
          v-if="this.WorkspaceHubs && this.WorkspaceHubs.length > 0"
          id="hubs"
        >
          <div class="general-form-section-title">{{ $t('hubsInformation') }}</div>
          <div class="general-form-section-description">
            {{ $t('hubsDescription.first') }}
            <div>{{ $t('hubsDescription.second') }}</div>
          </div>
          <div
            class="general-form-section-content-textfield"
            v-for="(workspaceHub, index) in this.WorkspaceHubs"
            :key="`hub-${index}-${commentSubmitStatus[workspaceHub.hub_id]}`"
            ref="hubs"
          >
            <div class="general-form-section-content-title">
              {{ workspaceHub.hub.name }}
            </div>
            <text-area
              :fieldModel.sync="$v.comment.$model[workspaceHub.hub_id]"
              :placeholder="$t('hubSettingsPlaceholder')"
              :errorMessage="getCommentValidationMessage(workspaceHub.hub_id)"
              :value="workspaceHub.comment"
              ref="comment"
              @handleInput="handleCommentChange(workspaceHub.hub_id)"
              :isForCommentSetting="true"
              class="general-form-section-content-textarea"
            />
            <div class="general-form-section-button">
              <submit-button
                :label="$t('save')"
                :disabled="disableCommentSaveButton(workspaceHub.hub_id)"
                :pending="commentSubmitStatus[workspaceHub.hub_id] === 'PENDING'"
                @submit="saveHubComment(workspaceHub.hub_id)"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        class="general-form-section"
        v-if="this.Workspace.owner_id === this.currentWorkspace.owner_id"
      >
        <div class="general-form-section-title">{{ $t('deleteWorkspace.sectionTitle') }}</div>
        <div class="general-form-section-content-title">{{ $t('deleteWorkspace.title') }}</div>
        <div class="general-form-section-content-text">{{ $t('deleteWorkspace.text') }}</div>
        <div class="general-form-section-button">
          <submit-button
            :label="$t('deleteWorkspace.title')"
            size="large"
            btnStyle="secondary"
            danger
            @submit="openDeleteWorkspaceModal"
          />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import get from 'lodash.get';
import { required, maxLength, minLength } from 'vuelidate/lib/validators';
import { getFileSize } from '@/utils/functions/audio';
import { COLORS, appConfig } from '@/utils/constants';
import { getTotalTracks, getTracksLeft } from '@/utils/functions/subscription';
import { getWorkspaceTrackLimit, isFreeTrial } from '@/utils/functions/workspace';
import { WORKSPACE, ME } from '@/graphql/queries/user';
import { UPDATE_WORKSPACE } from '@/graphql/mutations/workspace';
import { bus } from '@/utils/bus';
import { getGraphQlErrorCode, formatToNavigatorLanguage } from '@/utils/functions/global';
import { handleWorkspaceLogoUpload } from '@/utils/functions/upload';
import SaveChangesModal from '@/containers/modals/saveChanges';
import DeleteWorkspaceModal from '@/containers/modals/deleteWorkspace';
import { HUB_SET_WORKSPACE_COMMENT } from '@/graphql/mutations/hubs';
import { WORKSPACE_HUBS } from '@/graphql/queries/hubs';

export default {
  props: {
    isWorkspaceAdmin: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      imgUrl: null,
      blob: null,
      name: '',
      nameDisableStatus: true,
      nameSubmitStatus: 'OK',
      cropperMaxWidth: appConfig.upload.user.width,
      cropperMaxHeight: appConfig.upload.user.height,
      randomKey: 0,
      errorKey: null,
      comment: [],
      commentSubmitStatus: []
    };
  },
  apollo: {
    me: {
      query: ME
    },
    Workspace: {
      query: WORKSPACE,
      variables() {
        return {
          workspaceId: this.currentWorkspace.id
        };
      }
    },
    WorkspaceHubs: {
      query: WORKSPACE_HUBS,
      variables() {
        return {
          workspace_id: this.currentWorkspace.id
        };
      },
      result(res) {
        if (res.data) {
          this.WorkspaceHubs = res.data.WorkspaceHubs;
          if (this.WorkspaceHubs.length > 0) {
            this.$nextTick(() => {
              // Set default submit status
              this.WorkspaceHubs.forEach(i => {
                this.commentSubmitStatus[i.hub_id] = 'OK';
              });
            });
          }
        }
      }
    }
  },
  updated() {
    if (window.location.hash.includes('hubs')) {
      const hubTextarea = get(this, '$refs.hubs[0]');
      if (hubTextarea) {
        hubTextarea.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      const firstTextarea = get(this, '$refs.comment[0].$refs.textarea');
      if (firstTextarea) {
        setTimeout(() => {
          firstTextarea.focus();
          window.history.pushState(
            '',
            document.title,
            window.location.pathname + window.location.search
          );
        }, 500);
      }
    }
  },
  validations: {
    name: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(25)
    },
    comment: {
      required
    }
  },
  computed: {
    ...mapGetters(['currentWorkspace']),
    totalSpace() {
      return this.currentWorkspace.subscription.storage * 1000000;
    },
    getWorkpaceTotalTracksCount() {
      return getTotalTracks(this.currentWorkspace) + getTracksLeft(this.currentWorkspace);
    },
    getWorkspaceTracksCount() {
      return `${formatToNavigatorLanguage(
        getTotalTracks(this.currentWorkspace)
      )} / ${formatToNavigatorLanguage(getWorkspaceTrackLimit(this.currentWorkspace))} Tracks`;
    },
    progressBarStorageValue() {
      return this.spaceUsed > 0 ? Math.round((this.spaceUsed * 100) / this.totalSpace) : 0;
    },
    progressBarTracksValue() {
      const value = Math.round(
        (getTotalTracks(this.currentWorkspace) / this.getWorkpaceTotalTracksCount) * 100
      );
      return value > 1 ? value : 1;
    },
    progressBarStorageColor() {
      return this.getProgressBarColor(this.progressBarStorageValue);
    },
    progressBarTracksColor() {
      return this.getProgressBarColor(this.progressBarTracksValue);
    },
    spaceUsed() {
      return parseInt(this.currentWorkspace.space_used, 10);
    },
    spaceUsedInOctets() {
      return getFileSize(this.spaceUsed);
    },
    totalSpaceInOctets() {
      return getFileSize(this.totalSpace);
    },
    nameValidationMessage() {
      if (!this.$v.name.required && this.$v.name.$dirty) {
        return this.$t('required', { label: this.$t('workspaceName') });
      }
      if (!this.$v.name.maxLength) {
        return this.$t('maxLength', {
          label: this.$t('workspaceName'),
          nbChars: this.$v.name.$params.maxLength.max
        });
      }
      if (!this.$v.name.minLength) {
        return this.$t('minLength', {
          label: this.$t('workspaceName'),
          nbChars: this.$v.name.$params.minLength.min
        });
      }
      return null;
    },
    saveChangesModal() {
      return {
        title: null,
        size: 'medium',
        isVisible: false,
        component: SaveChangesModal,
        onSubmit: this.saveChanges,
        onCancel: this.cancelChanges
      };
    }
  },
  mounted() {
    this.imgUrl = this.currentWorkspace.logo && this.currentWorkspace.logo.url;
    this.name = this.currentWorkspace.name;
  },
  beforeRouteLeave(to, from, next) {
    if (this.nameDisableStatus) {
      next();
    } else {
      bus.$emit('displayModal', { ...this.saveChangesModal, isVisible: true });
    }
  },
  methods: {
    saveChanges() {
      this.updateWorkspace();
    },
    cancelChanges() {
      this.randomKey = Math.floor(Math.random() * 100000);
      this.nameDisableStatus = true;
    },
    changeImgUrl(url) {
      this.blob = url;
      this.imgUrl = null;
    },
    cropImage(blob, url) {
      this.imgUrl = url || get(this.currentWorkspace, 'logo.url', null);
      if (blob) {
        this.updateWorkspaceLogo(blob);
      }
      this.blob = null;
    },
    handleNameChange() {
      if (this.currentWorkspace.name !== this.$v.name.$model) this.nameDisableStatus = false;
      if (this.nameValidationMessage) this.nameDisableStatus = true;
    },
    updateWorkspace() {
      this.nameSubmitStatus = 'PENDING';
      this.$apollo
        .mutate({
          mutation: UPDATE_WORKSPACE,
          variables: {
            workspaceId: this.currentWorkspace.id,
            name: this.$v.name.$model
          },
          update: (store, { data: { updateWorkspace } }) => {
            const data = store.readQuery({ query: WORKSPACE });
            const workspaceIndex = data.me.workspaces.findIndex(
              w => w.id === this.currentWorkspace.id
            );
            data.me.workspaces[workspaceIndex] = {
              ...updateWorkspace,
              __typename: 'Workspace'
            };
            store.writeQuery({ query: WORKSPACE, data });
          }
        })
        .then(() => {
          this.nameSubmitStatus = 'OK';
          this.nameDisableStatus = true;
          bus.$emit('showAlert', {
            message: { key: 'modificationSuccess' },
            style: 'success',
            delay: 5000
          });
        })
        .catch(error => {
          this.nameSubmitStatus = 'OK';
          this.nameDisableStatus = true;
          const key = getGraphQlErrorCode(error);
          bus.$emit('showAlert', {
            message: { key },
            style: 'danger',
            delay: 5000,
            error: true
          });
        });
    },
    updateWorkspaceLogo(blob) {
      const file = new File([blob], 'newWorkspaceLogo', { type: 'image/jpeg' });
      handleWorkspaceLogoUpload(this, file, this.currentWorkspace.id);
    },
    openDeleteWorkspaceModal() {
      const member = get(this.currentWorkspace, 'members', []).find(m => m.user_id === this.me.id);
      const email = member ? member.email : undefined;
      bus.$emit('displayModal', {
        component: DeleteWorkspaceModal,
        isVisible: true,
        size: 'small',
        onSubmit: this.deleteWorkspace,
        title: this.$t('deleteWorkspace.title'),
        props: [
          { name: 'workspaceId', value: this.currentWorkspace.id },
          { name: 'email', value: email },
          { name: 'isFreeTrial', value: isFreeTrial(this.currentWorkspace) }
        ]
      });
    },
    getProgressBarColor(value) {
      if (value < 75) {
        return COLORS.green;
      }
      return COLORS.orange;
    },
    getCommentValidationMessage(id) {
      const model = this.$v.comment.$model;
      const currentHub = this.WorkspaceHubs.find(i => {
        return i.hub_id === id;
      });
      if (
        (currentHub.comment === '' && model[id] === '' && !this.$v.comment.$params.required[id]) ||
        (model[id] === '' && !this.$v.comment.$params.required[id])
      ) {
        return this.$t('required', { label: this.$t('contactInformation') });
      }
      return null;
    },
    handleCommentChange(id) {
      if (this.getCommentValidationMessage(id) === null) {
        this.commentSubmitStatus[id] = 'OK';
      }
    },
    disableCommentSaveButton(id) {
      const model = this.$v.comment.$model;
      const currentHub = this.WorkspaceHubs.find(i => {
        return i.hub_id === id;
      });
      return (
        typeof model[id] === 'undefined' || // Not initialized
        model[id] === '' || // Empty string
        model[id] === currentHub.comment // same value returned by API
      );
    },
    saveHubComment(hubId) {
      // Creating a new array allow Vue to re-render the view
      this.commentSubmitStatus = [...this.commentSubmitStatus];
      this.commentSubmitStatus[hubId] = 'PENDING';
      const comment = this.$v.comment.$model[hubId];
      this.$apollo
        .mutate({
          mutation: HUB_SET_WORKSPACE_COMMENT,
          variables: {
            hubId,
            workspaceId: this.currentWorkspace.id,
            comment
          },
          optimisticResponse: {
            __typename: 'Mutation',
            HubSetWorkspaceComment: {
              hub_id: hubId,
              workspace_id: this.currentWorkspace.id,
              comment,
              __typename: 'HubWorkspace'
            }
          },
          update: store => {
            const stored = store.readQuery({
              query: WORKSPACE_HUBS,
              variables: {
                workspace_id: this.currentWorkspace.id
              }
            });
            stored.WorkspaceHubs.find(i => {
              return i.hub_id === hubId;
            }).comment = comment;
            store.writeQuery({
              query: WORKSPACE_HUBS,
              variables: {
                workspace_id: this.currentWorkspace.id
              },
              data: stored
            });
          }
        })
        .then(() => {
          // Creating a new array allow Vue to re-render the view
          this.commentSubmitStatus = [...this.commentSubmitStatus];
          this.commentSubmitStatus[hubId] = 'OK';
          bus.$emit('showAlert', {
            message: { key: 'modificationSuccess' },
            style: 'success',
            delay: 5000
          });
        })
        .catch(error => {
          // Creating a new array allow Vue to re-render the view
          this.commentSubmitStatus = [...this.commentSubmitStatus];
          this.commentSubmitStatus[hubId] = 'OK';
          const key = getGraphQlErrorCode(error);
          bus.$emit('showAlert', {
            message: { key },
            style: 'danger',
            delay: 5000,
            error: true
          });
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.general {
  width: 100%;
  position: relative;

  &-cropper {
    position: absolute;
    background-color: $color_neutral_0;
    z-index: 2;
    left: -24px;
    height: 459px;
    top: 0;
    width: 100%;
  }

  &-form {
    &-section {
      border-bottom: 1px dotted $color_neutral_40;
      margin: 32px 0 40px;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        border: none;
      }

      &-title {
        @include heading-6;
        color: $color_neutral_100;
      }

      &-description {
        margin: 4px 0 0 0;
        @include body-2;
        color: $color_neutral_60;
        max-width: 550px;
      }

      &-button {
        display: flex;
        justify-content: flex-start;
        margin-top: 24px;
        margin-bottom: 40px;
      }

      &-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 24px 0 0;

        &-textfield {
          width: 522px;
          margin: 24px 0 0 0;
        }
        &-title {
          font-size: 12px;
          font-weight: 600;
          line-height: 18px;
          color: $color_neutral_100;
          margin: 24px 0 8px;
        }
        &-text {
          font-size: 12px;
          font-weight: normal;
          line-height: 18px;
          color: $color_neutral_60;
          margin-bottom: 24px;
        }
      }

      &-progressbar {
        margin: 24px 0 24px 0;

        &-bar {
          display: flex;

          &-containers {
            display: block;

            .progress-bar {
              margin-bottom: 16px;
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
.general-form-section-content-textarea {
  .area-error {
    border: 1px solid $color_danger_100;
    textarea {
      color: $color_danger_100;
      background-color: $color-danger_10;
    }
  }
  .area-errormsg {
    left: 0;
  }
}
</style>
