import { AnalyticsBrowser } from '@segment/analytics-next';

import { getConnectedAs, getFromLocal, getWorkspaceIdFromStorage } from '@/utils/storage';

import { appConfig } from '@/utils/constants';

let analytics;
const segmentAnonymousId = getFromLocal('ajs_anonymous_id')
  ? getFromLocal('ajs_anonymous_id').replaceAll('"', '')
  : '';
const isConnectedAs = getConnectedAs();

export const initAnalytics = segmentKey => {
  analytics = AnalyticsBrowser.load({ writeKey: segmentKey });
};

export const identify = (userId, user) => {
  if (isConnectedAs || !analytics) {
    return;
  }
  analytics.identify(userId, user);
};

export const trackEvent = (eventName, properties = {}, integrations = {}) => {
  if (isConnectedAs || !analytics) {
    return;
  }
  analytics.track(
    eventName,
    {
      ...properties,
      anonymousId: segmentAnonymousId
    },
    {
      integrations,
      groupId: getWorkspaceIdFromStorage(),
      app: {
        name: 'webapp',
        version: appConfig.version.release,
        build: appConfig.version.build
      }
    }
  );
};

export const trackPage = to => {
  if (isConnectedAs || !analytics) {
    return;
  }
  if (segmentAnonymousId) {
    analytics.page(to, { anonymousId: segmentAnonymousId });
  } else {
    // For first load we try to get the anonymousId once segment is ready
    analytics.ready(() => {
      analytics.user().then(segmentUser => {
        if (!segmentUser) {
          console.error('Segment setup script failed to initialize'); /* eslint-disable-line */
          return;
        }
        const anonymousId = segmentUser.anonymousId();
        analytics.page({ anonymousId });
      });
    });
  }
};

export const trackUploadClickEvent = routeName => {
  let page_view;
  switch (routeName) {
    case 'albums':
      page_view = 'library album';
      break;
    case 'artists':
      page_view = 'library artist';
      break;
    case 'trackList':
    default:
      page_view = 'library track list';
      break;
  }
  trackEvent('File Upload Click', {
    category: 'upload',
    page_view
  });
};
