<template>
  <div>
    <div>
      <h2 class="headline">{{ $t('subscription.headline.yourPlan') }}</h2>
      <div class="box">
        <div class="firstRow">
          <div class="banner" v-if="isFreeTrialEnabled">
            <div class="bannerHeader">{{ $t('freeTrialBanner.title') }}</div>
            <p class="bannerText">{{ $t('freeTrialBanner.billingText') }}</p>
          </div>
          <div class="banner bannerCanceled" v-if="isSubscriptionCanceled">
            <p class="bannerText">
              <i class="ri-error-warning-fill bannerIcon"></i>
              {{ $t('subscriptionCanceledBanner', { date: getBillingDate }) }}
            </p>
          </div>
          <hgroup>
            <h2 class="headline">{{ getCurrentPlanName }}</h2>
            <h4 class="headlinePeriod" v-if="getSubscriptionPeriod && !isFreeTrialEnabled">
              ({{ getSubscriptionPeriod }})
            </h4>
          </hgroup>
          <submit-button
            class="upgradePlan"
            :label="$t('availablePlans')"
            btnStyle="primary"
            @submit="navigateToOffers"
          />
        </div>
        <div class="secondRow">
          <div class="rowItem" v-if="isFreeTrialEnabled">
            <p class="rowItemTitle">Days left on trial</p>
            <p class="rowItemData">{{ getSubscriptionFreeTrialDaysLeft }}</p>
          </div>
          <div class="rowItem" v-if="getCurrentPlanName === 'pro'">
            <p class="rowItemTitle">Next invoice</p>
            <p class="rowItemData">{{ getNextInvoiceDate() }}</p>
          </div>
          <div class="rowItem" v-if="getCurrentPlanName === 'pro' || isFreeTrialEnabled">
            <p class="rowItemTitle">
              {{
                `Next invoice total (billed ${
                  this.getSubscriptionPeriod === 'yearly' ? 'annually' : 'monthly'
                })`
              }}
            </p>
            <p class="rowItemData" v-if="getNextInvoicePrice()">{{ getNextInvoicePrice() }}</p>
            <p class="rowItemData" v-else>
              <spinner-without-progress color="grey" :size="24" />
            </p>
          </div>
          <div class="rowItem">
            <p class="rowItemTitle">{{ $tc('membersLower', getWorkspaceMembersCount()) }}</p>
            <p class="rowItemData">{{ getWorkspaceMembersCount() }}</p>
            <p class="rowItemInfo">First 5 members are free</p>
          </div>
          <div class="rowItem">
            <p class="rowItemTitle">{{ $t('storageTitle') }}</p>
            <p class="rowItemData">{{ getWorkspaceTracksCount() }} Tracks</p>
            <p class="rowItemData">{{ getWorkspaceStorageCount() }} Gb</p>
          </div>
        </div>
      </div>
      <div v-if="getCurrentPlanName === 'pro' || getCurrentPlanName === 'Free trial'">
        <div>
          <div v-if="isBillingDetailsFlagEnabled">
            <hr class="separator" />
            <h2 class="headline">Billing details</h2>
            <div class="box secondRow">
              <div class="rowItem">
                <billing-user-card
                  :card="getStripeUserCard"
                  :prepareForPayment="prepareForPayment"
                  :hasPaymentMethod="hasPaymentMethod"
                />
              </div>
              <div class="rowItem">
                <billing-user-email
                  :address="getStripeUserAddress"
                  :email="getStripeUserEmail"
                  :company="getStripeUserCompany"
                  :prepareForPayment="prepareForPayment"
                  :hasPaymentMethod="hasPaymentMethod"
                />
              </div>
              <div class="rowItem">
                <billing-user-address
                  :email="getStripeUserEmail"
                  :address="getStripeUserAddress"
                  :company="getStripeUserCompany"
                  :vat="getStripeUserVat"
                  :prepareForPayment="prepareForPayment"
                  :hasPaymentMethod="hasPaymentMethod"
                />
              </div>
            </div>
          </div>
        </div>
        <div v-if="BillsList && BillsList.length > 0">
          <hr class="separator" />
          <payment-invoice :invoices="BillsList" :subscriptions="getWorkspaceSubscription" />
        </div>
        <hr class="separator" />
        <div v-if="isFreeTrialEnabled">
          <div v-if="!hasPaymentMethod">
            <h2 class="headline">Your plan information</h2>
            <p class="paragraph">
              At the end of your 14-day free trial, your workspace will be downgraded to a Free
              plan. No worries! You can always upgrade to Pro at any time by adding your billing
              information in the “Billing details” section.
            </p>
          </div>
        </div>
        <div v-else>
          <payment-cancellation
            :isCanceled="isSubscriptionCanceled"
            :billingDate="getBillingDate"
            :email="getUserEmail"
            @cancelSubscription="handleCancelSubscription"
          />
        </div>
      </div>
      <div v-else>
        <div v-if="BillsList && BillsList.length > 0">
          <hr class="separator" />
          <payment-invoice :invoices="BillsList" :subscriptions="getWorkspaceSubscription" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { format } from 'date-fns';
import get from 'lodash.get';

import { ME, BILLS_LIST } from '@/graphql/queries/user';
import { GET_PLANS } from '@/graphql/queries/plans';
import {
  STRIPE_CUSTOMER,
  STRIPE_CALCULATE_SUBSCRIPTION_PRICE,
  STRIPE_CUSTOMER_CARD
} from '@/graphql/queries/subscription';
import { bus } from '@/utils/bus';
import { formatPrice } from '@/utils/functions/user';
import {
  isSubscriptionYearly,
  hasActiveSubscription,
  isFreeTrial,
  getFreeTrialDaysLeft,
  isSubscriptionCanceled
} from '@/utils/functions/workspace';
import PaymentCancellation from '@/containers/paymentCancellation';
import PaymentInvoice from '@/containers/paymentInvoice';
import BillingUserCard from '@/containers/billingUserCard';
import BillingUserEmail from '@/containers/billingUserEmail';
import BillingUserAddress from '@/containers/billingUserAddress';
import { get as getRoute } from '@/router/routes';
import { appConfig } from '@/utils/constants';
import { cancelSubscription } from '@/utils/actions/subscriptions';

export default {
  components: {
    PaymentCancellation,
    PaymentInvoice,
    BillingUserCard,
    BillingUserEmail,
    BillingUserAddress
  },
  data() {
    return {
      isYearly: true,
      displayStepPricing: false,
      displayStropPayment: false,
      selectedPlanIndex: 0,
      nextInvoicePrice: undefined
    };
  },
  apollo: {
    plans: {
      query: GET_PLANS
    },
    me: {
      query: ME,
      fetchPolicy: 'network-only'
    },
    BillsList: {
      query: BILLS_LIST,
      fetchPolicy: 'network-only',
      variables() {
        return {
          workspaceId: this.currentWorkspace.id
        };
      }
    },
    StripeCustomerCards: {
      query: STRIPE_CUSTOMER_CARD,
      fetchPolicy: 'network-only',
      variables() {
        return {
          workspaceId: this.currentWorkspace.id
        };
      },
      skip() {
        return !hasActiveSubscription(this.currentWorkspace);
      }
    },
    PaymentStripeCustomer: {
      query: STRIPE_CUSTOMER,
      fetchPolicy: 'network-only',
      variables() {
        return {
          workspaceId: this.currentWorkspace.id
        };
      },
      skip() {
        return !hasActiveSubscription(this.currentWorkspace);
      }
    }
  },
  async beforeMount() {
    // We add Strip script only once
    if (document.getElementById(appConfig.stripeScriptId) === null) {
      const stripeScript = document.createElement('script');
      stripeScript.setAttribute('async', true);
      stripeScript.setAttribute('id', appConfig.stripeScriptId);
      stripeScript.setAttribute('src', 'https://js.stripe.com/v3/');
      document.head.appendChild(stripeScript);
    }
    if (hasActiveSubscription(this.currentWorkspace)) {
      // Loads the next invoice price
      const seats = this.currentWorkspace.seats_used === -1 ? 1 : this.currentWorkspace.seats_used;
      const variables = {
        workspaceId: this.currentWorkspace.id,
        planVariantId: get(this.currentWorkspace, 'subscription.plan.variant_id'),
        seats,
        subscriptionId: this.currentWorkspace.subscription.payment_information.subscription_id,
        billingPeriod: this.currentWorkspace.subscription.payment_information.period
      };
      const apiResponse = await this.$apollo.query({
        query: STRIPE_CALCULATE_SUBSCRIPTION_PRICE,
        fetchPolicy: 'no-cache',
        variables
      });
      const result = get(apiResponse, 'data.StripeCalculateSubscriptionPrice');
      this.nextInvoicePrice = formatPrice(result.due_today_amount, result.currency);
    }
  },
  created() {
    bus.$on('refetchStripeCustomerCards', this.refetchStripeCustomerCards);
    bus.$on('refetchStripeCustomer', this.refetchStripeCustomer);
  },
  beforeDestroy() {
    bus.$off('refetchStripeCustomerCards');
    bus.$off('refetchStripeCustomer');
  },
  computed: {
    ...mapGetters(['currentWorkspace', 'userCurrency']),
    isFreeTrialEnabled() {
      return isFreeTrial(this.currentWorkspace);
    },
    getStripeUserCard() {
      if (!this.StripeCustomerCards) {
        return undefined;
      }
      return get(this.StripeCustomerCards, '[0]', {});
    },
    getStripeUserEmail() {
      if (!this.PaymentStripeCustomer) {
        return undefined;
      }
      return get(this.PaymentStripeCustomer, 'billing_information.email', '');
    },
    getStripeUserAddress() {
      return get(this.PaymentStripeCustomer, 'billing_information.address');
    },
    getStripeUserCompany() {
      return get(this.PaymentStripeCustomer, 'billing_information.company');
    },
    getStripeUserVat() {
      return get(this.PaymentStripeCustomer, 'billing_information.vat');
    },
    getUserEmail() {
      return get(this.me, 'email', '');
    },
    getBillingDate() {
      const billingDate = get(this.currentWorkspace, 'subscription.end_at');
      return format(new Date(parseInt(billingDate, 10) * 1000), 'MMMM dd, yyyy');
    },
    getCurrentPlanName() {
      const planName = get(this.currentWorkspace, 'subscription.plan.name', '');
      return isFreeTrial(this.currentWorkspace) ? 'Free trial' : planName.toLowerCase();
    },
    getSubscriptionFreeTrialDaysLeft() {
      return `${getFreeTrialDaysLeft(this.currentWorkspace)} days left`;
    },
    getSubscriptionPeriod() {
      return get(this.currentWorkspace, 'subscription.payment_information.period');
    },
    getWorkspaceSubscription() {
      return get(this.currentWorkspace, 'subscription');
    },
    isBillingDetailsFlagEnabled() {
      return appConfig.flags.billingDetails;
    },
    hasPaymentMethod() {
      const card = this.StripeCustomerCards;
      return card === undefined || card.length > 0;
    },
    isSubscriptionCanceled() {
      return isSubscriptionCanceled(this.currentWorkspace);
    }
  },
  methods: {
    prepareForPayment() {
      this.$apollo.query({ query: GET_PLANS, fetchPolicy: 'network-only' }).then(response => {
        const plan = response.data.plans.find(i => i.slug === 'pro');
        const selectedPlan = plan.variants.find(
          i => i.id === this.currentWorkspace.subscription.plan.variant_id
        );
        /* Needs to store this in store to be able to retriev information on payment form */
        this.$store.commit('setSelectedPlan', {
          isYearly: isSubscriptionYearly(this.currentWorkspace),
          plan: selectedPlan
        });
        this.$router.push(getRoute('paymentSettings'));
      });
    },
    navigateToOffers() {
      this.$router.push(getRoute('offersSettings'));
    },
    getWorkspaceMembersCount() {
      return this.currentWorkspace.seats_used;
    },
    getWorkspaceTracksCount() {
      const tracksLeft = this.currentWorkspace.tracks_left;
      const tracksUploaded = this.currentWorkspace.total_tracks;
      return `${tracksUploaded} / ${tracksUploaded + tracksLeft}`;
    },
    getNextInvoiceDate() {
      const date = get(this.currentWorkspace, 'subscription.end_at');
      return format(date * 1000, 'LLL d, y');
    },
    getNextInvoicePrice() {
      if (isSubscriptionCanceled(this.currentWorkspace)) {
        return formatPrice(0, this.userCurrency);
      }
      return this.nextInvoicePrice;
    },
    getWorkspaceStorageCount() {
      return `${(this.currentWorkspace.space_used / 1000 / 1000 / 1000).toFixed(2)} / ${
        this.currentWorkspace.subscription.storage / 1000
      }`;
    },
    handleCancelSubscription(password) {
      cancelSubscription(this, password);
    },
    refetchStripeCustomerCards() {
      this.$apollo.queries.StripeCustomerCards.refetch();
    },
    refetchStripeCustomer() {
      this.$apollo.queries.PaymentStripeCustomer.refetch();
    }
  }
};
</script>

<style lang="scss" scoped>
.headline {
  text-transform: capitalize;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 16px;
  display: inline-block;
}

.headlinePeriod {
  padding-left: 8px;
  font-size: 14px;
  color: $color_neutral_60;
  display: inline-block;
}

.box {
  border: 1px solid $color_neutral_30;
  box-sizing: border-box;
  border-radius: 8px;
}

.firstRow {
  border-bottom: 1px solid $color_neutral_30;
  padding: 24px;
}

.secondRow {
  display: flex;
}

.banner {
  color: $color_primary_100;
  background: $color_primary_10;
  padding: 16px;
  margin-bottom: 16px;
  border-radius: 4px;
}
.bannerCanceled {
  background: $color_warning_10;
  color: $color_warning_110;
}

.bannerIcon {
  font-size: 20px;
  padding-right: 12px;
}

.bannerHeader {
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
  margin-bottom: 8px;
}

.bannerText {
  font-size: 14px;
  line-height: 125%;
  display: flex;
  align-items: center;
}

.rowItem {
  padding: 24px;
  flex: 1;
  border-right: 1px solid $color_neutral_30;
  position: relative;
}

.rowItem:last-child {
  border-right: none;
}

.rowItemTitle {
  color: $color_neutral_60;
  font-size: 12px;
  margin-bottom: 8px;
  &:first-letter {
    text-transform: uppercase;
  }
}

.rowItemData {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 8px;
  line-height: 120%;
}

.rowItemInfo {
  color: $color_neutral_60;
  font-weight: 400;
  font-size: 12px;
  margin-bottom: 8px;
  line-height: 150%;
}

.paragraph {
  color: $color_neutral_60;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  max-width: 40rem;
}

.separator {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid $color_neutral_30;
  margin: 40px 0;
  padding: 0;
}
</style>
<style lang="scss">
.upgradePlan.button.primary.medium.no-icon {
  background: $color_primary_100;
  &:hover {
    background: $color_blue_50;
  }
  &:active {
    box-shadow: 0px 0px 0px 1px $color_neutral_0, 0px 0px 0px 3px $color_neutral_40;
  }
}
</style>
