<template>
  <div class="container">
    <div class="formOverlay" v-if="paymentStatus">
      <div class="formProcessing">
        <div v-if="isPaymentProcessing">
          <spinner-without-progress color="grey" :size="24" />
          <div class="formProcessingMessage">Processing payment…</div>
        </div>
        <div v-if="isPaymentFailed">
          <i class="ri-alert-fill formErrorIcon" />
          <p class="formProcessingMessage">Payment failed</p>
          <p class="formErrorInformations" v-if="paymentErrorCode === 24">
            Looks like we encountered an error while setting up your VAT informations. If you
            continue to have issues, please contact us.
          </p>
          <p v-else>
            Looks like we encountered an error. Please try again. If you continue to have issues,
            try another payment method.
          </p>
          <p class="formErrorInformations">
            <span>{{ paymentErrorMessage }}</span>
            <span class="formErrorCode">(Error : {{ paymentErrorCode }})</span>
          </p>
          <div class="retryButton">
            <submit-button label="Try again" @submit="tryAgain" />
          </div>
        </div>
        <div v-if="isPaymentSuccessful" class="form-Processing">
          <spinner-without-progress color="grey" :size="24" />
          <div class="formProcessingMessage">Activating subscription…</div>
        </div>
      </div>
    </div>
    <form class="form" @submit="$v.$touch()" id="payment-form" :class="{ loader: paymentStatus }">
      <div class="leftPart">
        <fieldset>
          <legend class="formTitle">Your location</legend>
          <div class="inputLine multiInputLine">
            <select-box
              :options="getCountryList"
              placeholder=""
              label="Country"
              :selectedValue="getUserCountry"
              @setSelectBoxValue="selectCountry"
              large="large"
            />
            <text-field
              inputType="text"
              label="State"
              :large="true"
              :value="state"
              :fieldModel.sync="$v.state.$model"
              helper="(Optionnal)"
              autocomplete="off"
            />
          </div>
          <text-field
            inputType="text"
            label="Street address"
            :large="true"
            :value="address"
            autocomplete="off"
            :errorMessage="addressValidationMessage"
            :fieldModel.sync="$v.address.$model"
            class="inputLine"
          />
          <text-field
            inputType="text"
            label="Suite / Unit"
            :large="true"
            :value="addressSuite"
            :fieldModel.sync="$v.addressSuite.$model"
            helper="(Optionnal)"
            autocomplete="off"
            class="inputLine"
          />
          <div class="multiInputLine inputLine">
            <text-field
              inputType="text"
              label="City"
              :large="true"
              :value="city"
              :errorMessage="cityValidationMessage"
              :fieldModel.sync="$v.city.$model"
              autocomplete="off"
            />
            <text-field
              inputType="text"
              label="ZIP / Postal code"
              :large="true"
              :errorMessage="postalCodeValidationMessage"
              :fieldModel.sync="$v.postalCode.$model"
              :value="postalCode"
              autocomplete="off"
            />
          </div>
        </fieldset>
        <fieldset>
          <legend class="formTitle">Your company</legend>
          <text-field
            inputType="email"
            label="Billing email address"
            class="inputLine"
            autocomplete="off"
            :large="true"
            :value="userPrimaryEmail"
            :errorMessage="emailValidationMessage"
            :fieldModel.sync="$v.emailAddress.$model"
          />
          <div class="multiInputLine inputLine">
            <text-field
              inputType="text"
              label="Company name"
              autocomplete="off"
              :large="true"
              :fieldModel.sync="$v.companyName.$model"
              :errorMessage="companyNameMessage"
            />
            <text-field
              inputType="text"
              label="Tax number"
              helper="(Optionnal)"
              autocomplete="off"
              :large="true"
              :value="taxNumber"
              :fieldModel.sync="$v.taxNumber.$model"
            />
          </div>
        </fieldset>
        <fieldset>
          <legend class="formTitle">Payment information</legend>
          <label class="stripeLabel">Credit card</label>
          <div id="card-element" ref="stripeElement">
            <!-- Elements will create input elements here -->
          </div>
          <div id="card-errors" role="alert"></div>
          <div class="formFooter">
            <img draggable="false" src="/images/powered_by_stripe.png" />
            <p>
              Credit Card details are securely processed by Stripe and never stored on our servers.
            </p>
          </div>
        </fieldset>
      </div>
      <div class="rightPart">
        <div class="rightPartSticky">
          <div class="formResume">
            <header class="planInfosHeader">
              <i class="ri-music-2-fill planIcon" />{{ planName }}
            </header>
            <div class="planInfosBody">
              <div>{{ getMonthlySubscriptionFormattedPrice }} {{ getFeatures }}</div>
              <div class="planInfosTotalPrice">{{ getSubscriptionFormattedPrice }}</div>
            </div>
            <div class="planInfosBody" v-if="hasMoreMemberThanIncludedInPlan">
              <div>{{ getFormattedExtraMembers }} {{ getExtraMembersFeatures }}</div>
              <div class="planInfosTotalPrice">{{ getExtraMembersFormattedPrice }}</div>
            </div>
            <div class="planInfosBody planCoupon" v-if="!isCouponValid">
              <label class="couponLabel">Discount code</label>
              <div>
                <input
                  type="text"
                  ref="couponInput"
                  autocomplete="off"
                  :value="coupon"
                  @keyup="setCouponValue"
                  :class="{ couponInput: true, invalidCouponInput: isCouponValid === false }"
                />
                <div
                  v-if="isCouponValid === false && isCouponApplicable === undefined"
                  class="invalidCouponLabel"
                >
                  Enter a valid coupon code
                </div>
                <div
                  v-if="isCouponValid === false && isCouponApplicable === false"
                  class="invalidCouponLabel"
                >
                  This coupon can't be used with this plan
                </div>
              </div>
              <submit-button
                label="Apply"
                class="couponSubmit"
                :disabled="submitCouponDisabled"
                :pending="isCouponProcessing"
                @submit="submitCoupon"
              />
            </div>
            <div class="planInfosBody planCoupon" v-else>
              <div class="planInfosRow">
                <div class="planValidCoupon" v-if="displayCouponInput">
                  <label>Discount code</label>
                  <div class="couponGift"><i class="ri-gift-line" /> {{ coupon }}</div>
                </div>
                <div v-if="isCouponValid" class="planDiscount">
                  <label>Promotion</label>
                  <div class="planDiscountPrice">{{ getDiscountFormattedPrice }}</div>
                </div>
              </div>
            </div>
            <footer class="planInfosFooter">
              <div>{{ getSummaryLabel }}</div>
              <div class="planInfosTotalPrice">{{ getTotalFormattedPrice }}</div>
            </footer>
          </div>
          <input
            type="submit"
            :class="{ submitDisabled: isCouponProcessing || !isFormValid }"
            :value="getSubmitLabel"
          />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { countries } from 'countries-list';
import get from 'lodash.get';
import { required, email } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';

import {
  STRIPE_CREATE_SUBSCRIPTION,
  STRIPE_CREATE_CUSTOMER,
  STRIPE_SET_DEFAULT_PAYMENT_METHOD,
  STRIPE_UPDATE_SUBSCRIPTION
} from '@/graphql/mutations/subscription';
import { STRIPE_COUPON, STRIPE_CREATE_SETUP_INTENT } from '@/graphql/queries/subscription';
import { saveBillingInformations } from '@/utils/actions/subscriptions';
import { getFileSize } from '@/utils/functions/audio';
import { getGraphQlErrorData, getGraphQlErrorCode } from '@/utils/functions/global';
import { formatPrice } from '@/utils/functions/user';
import { getMembersSeats } from '@/utils/functions/subscription';
import { isFreeTrial, isSubscriptionCanceled } from '@/utils/functions/workspace';
import { apolloClient } from '../../main';

import ManropeRegular from '../../assets/fonts/Manrope-Regular.ttf';

import taxIds from '../../assets/taxids.json';

const ErrorCode = {
  PAYMENT_TIMEOUT: 0,
  APPLY_COUPON: 10,
  COUPON_INVALID: 11,
  CREATE_CUSTOMER: 20,
  CREATE_SUBSCRIPTION: 21,
  MISSING_CLIENT_SECRET: 22,
  MISSING_SUBSCRIPTION_ID: 23,
  MISSING_SETUP_INTENT: 23,
  INVALID_VAT_TYPE: 24,
  STRIPE_CREATE_PAYMENT_METHOD: 30,
  STRIPE_CONFIRM_CARD_PAYMENT: 31,
  STRIPE_MISSING_PAYMENT_METHOD_ID: 32
};

const Status = {
  PROCESSING: 'processing',
  SUCCESS: 'success',
  FAILED: 'failed'
};

const STRIPE_TIMEOUT_DELAY = 300000;

export default {
  props: {
    planName: {
      type: String,
      required: true
    },
    planVariant: {
      type: Object,
      required: true
    },
    workspaceId: {
      type: String,
      required: true
    },
    isYearly: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      stripeInstance: undefined,
      // Form data
      country: '',
      state: '',
      address: '',
      addressSuite: '',
      city: '',
      postalCode: '',
      emailAddress: '',
      companyName: '',
      taxNumber: '',
      hasPaymentMethod: false,

      // Payment
      paymentProcessing: false,
      paymentStatus: undefined,
      paymentErrorMessage: undefined,
      paymentErrorCode: undefined,

      // Coupon
      couponStatus: undefined,
      isCouponValid: undefined,
      isCouponApplicable: undefined,
      coupon: '',
      couponDiscount: undefined
    };
  },
  validations: {
    country: { required },
    address: { required },
    addressSuite: {},
    state: {},
    city: { required },
    postalCode: { required },
    emailAddress: { required, email },
    companyName: { required },
    taxNumber: {},
    coupon: {}
  },
  mounted() {
    // Set up Stripe.js and Elements to use in checkout form
    this.stripeInstance = window.Stripe(this.$config.stripe_pk);
    const elements = this.stripeInstance.elements({
      fonts: [
        // Elements are in iFrame so it must downloads its own fonts again
        {
          family: 'Manrope-Regular',
          src: `url("${document.location.origin}${ManropeRegular.toString()}")`,
          weight: '500'
        }
      ]
    });
    this.stripeCardElement = elements.create('card', {
      hidePostalCode: true,
      style: {
        base: {
          color: '#32325D',
          fontWeight: 500,
          fontFamily: 'Manrope-Regular',
          fontSize: '14px',
          fontSmoothing: 'antialiased',
          '::placeholder': {
            color: '#CFD7DF'
          },
          ':-webkit-autofill': {
            color: '#e39f48'
          }
        },
        invalid: {
          color: '#FF4533',
          '::placeholder': {
            color: 'black'
          }
        }
      }
    });
    this.stripeCardElement.mount('#card-element');
    this.stripeCardElement.on('change', event => {
      if (event.complete === false) {
        this.hasPaymentMethod = false;
      }
      if (event.complete === true) {
        this.hasPaymentMethod = true;
      }
    });
    const form = document.getElementById('payment-form');
    form.addEventListener('submit', event => {
      event.preventDefault();
      if (this.$refs.couponInput === document.activeElement) {
        return;
      }
      this.submitToStripe();
    });
    this.country = this.userCountry;
    this.emailAddress = this.userPrimaryEmail;
  },
  computed: {
    ...mapGetters(['currentWorkspace', 'userCurrency', 'userCountry', 'userPrimaryEmail']),
    displayCouponInput() {
      return !isFreeTrial(this.currentWorkspace);
    },
    getSubmitLabel() {
      if (isFreeTrial(this.currentWorkspace)) {
        return this.$t('saveBillingInfo');
      }
      return this.$t('completeTransaction');
    },
    getSummaryLabel() {
      if (isFreeTrial(this.currentWorkspace) || isSubscriptionCanceled(this.currentWorkspace)) {
        return 'Next invoice total';
      }
      return 'Due today';
    },
    isFormValid() {
      return (
        this.$v.$invalid === false &&
        this.hasPaymentMethod === true &&
        (this.coupon.trim().length === 0 || this.isCouponValid)
      );
    },
    getUserCountry() {
      const country = this.country || this.userCountry;
      return {
        label: `${countries[country].emoji}  ${countries[country].name}`,
        value: country
      };
    },
    getCountryList() {
      const countriesISO = Object.keys(countries);
      // Manually sort some items
      countriesISO.splice(countriesISO.indexOf('US'), 1);
      countriesISO.splice(0, 0, 'US');
      countriesISO.splice(countriesISO.indexOf('FR'), 1);
      countriesISO.splice(0, 0, 'FR');
      // Delete Kosovo as it use workspaceMemberCount temporary ISO code
      countriesISO.splice(countriesISO.indexOf('XK'), 1);
      return countriesISO.map(i => ({
        value: i,
        label: `${countries[i].emoji}  ${countries[i].name}`
      }));
    },
    countryValidationMessage() {
      return this.$v.country.required ? null : this.$t('subscription.form.countryRequired');
    },
    addressValidationMessage() {
      return this.$v.address.required ? null : this.$t('subscription.form.addressRequired');
    },
    cityValidationMessage() {
      return this.$v.city.required ? null : this.$t('subscription.form.cityRequired');
    },
    postalCodeValidationMessage() {
      return this.$v.postalCode.required ? null : this.$t('subscription.form.postalCodeRequired');
    },
    companyNameMessage() {
      return this.$v.companyName.required ? null : this.$t('subscription.form.companyNameRequired');
    },
    emailValidationMessage() {
      if (this.$v.emailAddress.$error) {
        if (this.$v.emailAddress.email) {
          return this.$t('subscription.form.emailAddressRequired');
        }
        if (this.$v.emailAddress.required) {
          return this.$t('subscription.form.invalidEmail');
        }
      }
      return null;
    },
    getMonthlySubscriptionFormattedPrice() {
      const monthlyPrice = this.isYearly
        ? this.getSubscriptionPrice / 12
        : this.getSubscriptionPrice;
      return formatPrice(monthlyPrice, this.userCurrency);
    },
    getSubscriptionFormattedPrice() {
      return formatPrice(this.getSubscriptionPrice, this.userCurrency);
    },
    getDiscountFormattedPrice() {
      return formatPrice(this.couponDiscount, this.userCurrency, true);
    },
    getExtraMembersFormattedPrice() {
      return formatPrice(this.getExtraMembersPrice, this.userCurrency);
    },
    getTotalFormattedPrice() {
      const plan = this.planVariant.prices.find(item => item.currency === this.userCurrency);
      let price = this.isYearly ? plan.yearly_price : plan.monthly_price;
      if (this.hasMoreMemberThanIncludedInPlan) {
        price += this.getExtraMemberCount * (this.isYearly ? 500 * 12 : 900);
      }
      if (this.isCouponValid) {
        price -= this.couponDiscount;
      }
      return formatPrice(price > 0 ? price : 0, this.userCurrency);
    },
    hasMoreMemberThanIncludedInPlan() {
      return this.getExtraMemberCount > 0;
    },
    getExtraMemberCount() {
      const memberFeature = this.planVariant.features.find(item => item.type === 'members');
      const planIncludedSeats = memberFeature.configuration.find(
        item => item.type === 'seats-included'
      ).options;
      const extraMemberCount = getMembersSeats(this.currentWorkspace) - planIncludedSeats;
      return extraMemberCount > 0 ? extraMemberCount : 0;
    },
    getFormattedExtraMembers() {
      return this.$tc('additionnalUsers', this.getExtraMemberCount, {
        count: this.getExtraMemberCount
      });
    },
    getFeatures() {
      const storageFeature = this.planVariant.features.find(item => item.type === 'storage');
      const memberFeature = this.planVariant.features.find(item => item.type === 'members');
      const storage = get(storageFeature, 'configuration[0].options');
      const formatedStorage = getFileSize(storage);
      const members =
        memberFeature.configuration.find(item => item.type === 'seats-included').options || 5;
      const annualSuffix = this.isYearly ? 'x 12 months' : '';
      return `(${formatedStorage} & ${members} members) ${annualSuffix}`;
    },
    getExtraMembersFeatures() {
      const extraMemberPrice = this.isYearly ? 500 : 900;
      return `(${formatPrice(extraMemberPrice, this.userCurrency)} each / month)`;
    },
    submitCouponDisabled() {
      return this.coupon.trim().length === 0 || this.couponStatus === Status.PROCESSING;
    },
    isCouponProcessing() {
      return this.couponStatus === Status.PROCESSING;
    },
    isPaymentProcessing() {
      return this.paymentStatus === Status.PROCESSING;
    },
    isPaymentFailed() {
      return this.paymentStatus === Status.FAILED;
    },
    isPaymentSuccessful() {
      return this.paymentStatus === Status.SUCCESS;
    },
    getSubscriptionPrice() {
      const plan = this.planVariant.prices.find(item => item.currency === this.userCurrency);
      return this.isYearly ? plan.yearly_price : plan.monthly_price;
    },
    getExtraMembersPrice() {
      return this.getExtraMemberCount * (this.isYearly ? 12 * 500 : 900);
    },
    getTotalPrice() {
      return this.getSubscriptionPrice + this.getExtraMembersPrice;
    }
  },
  methods: {
    async submitCoupon() {
      this.couponStatus = Status.PROCESSING;
      const apiResponse = await apolloClient
        .query({
          query: STRIPE_COUPON,
          fetchPolicy: 'network-only',
          variables: {
            couponId: this.coupon.trim(),
            planVariantId: this.planVariant.id
          }
        })
        .catch(error => {
          const errorCode = getGraphQlErrorCode(error);
          if (errorCode === 'NOT_FOUND') {
            this.isCouponValid = false;
          }
          this.couponStatus = Status.FAILED;
          this.paymentErrorCode = ErrorCode.APPLY_COUPON;
        });
      const couponData = get(apiResponse, 'data.PaymentStripeCoupon');
      if (!couponData) {
        this.couponStatus = Status.FAILED;
        this.paymentErrorCode = ErrorCode.COUPON_INVALID;
        return;
      }
      if (couponData.is_valid && couponData.is_applicable) {
        if (isFreeTrial(this.currentWorkspace)) {
          // Apply coupon on current subscription
          const currentSubcription = get(this.currentWorkspace, 'subscription');
          const seats =
            this.currentWorkspace.seats_used === -1 ? 1 : this.currentWorkspace.seats_used;
          await apolloClient.mutate({
            mutation: STRIPE_UPDATE_SUBSCRIPTION,
            variables: {
              subscriptionId: get(currentSubcription, 'payment_information.subscription_id'),
              planVariantId: get(currentSubcription, 'plan.variant_id'),
              billingPeriod: get(currentSubcription, 'payment_information.period'),
              billingCurrency: get(currentSubcription, 'payment_information.currency'),
              seats,
              coupon: this.coupon.trim()
            }
          });
          this.couponStatus = Status.SUCCESS;
        }
        if (couponData.amount_off) {
          this.couponDiscount = couponData.amount_off;
        } else {
          this.couponDiscount = (this.getTotalPrice * couponData.percent_off) / 100;
        }
        this.couponStatus = Status.SUCCESS;
        this.isCouponValid = true;
        this.isCouponApplicable = true;
      } else {
        this.couponStatus = Status.FAILED;
        this.isCouponValid = false;
        if (couponData.is_applicable === false) {
          this.isCouponApplicable = false;
        }
        console.error('coupon expired'); // eslint-disable-line
      }
    },
    async submitToStripe() {
      this.$v.$touch();
      const stripeItem = this.$refs.stripeElement;
      const isCardComplete = stripeItem && stripeItem.className.includes('StripeElement--complete');
      if (this.$v.$anyError === true || this.paymentStatus || isCardComplete !== true) {
        console.debug( // eslint-disable-line
          'Something is missing to process payment',
          this.$v.$anyError,
          this.paymentStatus,
          isCardComplete,
          this.coupon.trim().length,
          this.isCouponValid,
          this.isCouponApplicable
        );
        return;
      }
      const billingInformation = {
        address: {
          line1: this.address,
          line2: this.addressSuite,
          city: this.city,
          postal_code: this.postalCode,
          country: this.country,
          state: this.state
        },
        email: this.emailAddress
      };
      if (isFreeTrial(this.currentWorkspace)) {
        this.paymentStatus = Status.PROCESSING;
        this.paymentErrorCode = undefined;
        let submissionSuccessful = false;
        let errorCount = 0;
        const typeIds = taxIds.find(t => t.code === this.country).type;
        /* Important note :
        // Use this for loop in order to get sync promise resolution
        */
        for (const type of typeIds) { // eslint-disable-line
          if (submissionSuccessful) {
            // Once a submission is OK, we don't want to try other vat types
            continue; // eslint-disable-line
          }
          if (this.taxNumber && this.taxNumber !== '') {
            billingInformation.vat = {
              type,
              value: this.taxNumber
            };
          }
          await saveBillingInformations(this, { // eslint-disable-line
            workspaceId: this.currentWorkspace.id,
            billingInformation: {
              ...billingInformation,
              company: this.companyName
            }
          })
            .then(result => {// eslint-disable-line
              if (result) {
                submissionSuccessful = true;
              }
            })
            .catch(error => { // eslint-disable-line
              const errorData = getGraphQlErrorData(error);
              if (errorData.includes('VAT error')) {
                errorCount += 1;
                if (errorCount === typeIds.length) {
                  this.paymentStatus = Status.FAILED;
                  this.paymentErrorCode = ErrorCode.INVALID_VAT_TYPE;
                }
              } else {
                this.paymentStatus = Status.FAILED;
                this.paymentErrorCode = ErrorCode.CREATE_CUSTOMER;
              }
            });
        }
        const intentResponse = await apolloClient.query({
          query: STRIPE_CREATE_SETUP_INTENT,
          variables: {
            workspaceId: this.currentWorkspace.id
          },
          error() {
            this.paymentStatus = Status.FAILED;
            this.paymentErrorCode = ErrorCode.CREATE_SETUP_INTENT;
          }
        });
        if (this.paymentStatus !== Status.PROCESSING) {
          return;
        }

        const secret = get(intentResponse, 'data.StripeCreateSetupIntent.secret');
        // Must delete vat information for this call
        delete billingInformation.vat;
        this.stripeInstance
          .confirmCardSetup(secret, {
            payment_method: {
              card: this.stripeCardElement,
              billing_details: billingInformation
            }
          })
          .then(result => {
            if (get(result, 'setupIntent.status') === 'succeeded') {
              this.paymentStatus = Status.SUCCESS;
              setTimeout(() => {
                this.$emit('onPaymentSuccess');
                const currentSubcription = get(this.currentWorkspace, 'subscription');
                this.setPaymentAsDefault({
                  subscriptionId: get(currentSubcription, 'payment_information.subscription_id'),
                  paymentMethodId: get(result, 'setupIntent.payment_method')
                });
              }, 2000);
            } else {
              this.paymentStatus = Status.FAILED;
              this.paymentErrorCode = ErrorCode.STRIPE_CREATE_PAYMENT_METHOD;
            }
          });
      } else {
        if (
          this.coupon.trim().length > 0 &&
          this.isCouponValid === false &&
          this.isCouponApplicable === false
        ) {
          console.debug('subscription missing something'); /* eslint-disable-line */
          return;
        }
        this.paymentStatus = Status.PROCESSING;
        this.paymentErrorMessage = undefined;
        this.paymentErrorCode = undefined;

        // The process must take 30s maximum before we display the error modal
        const errorTimeout = setTimeout(() => {
          this.paymentStatus = Status.FAILED;
          this.paymentErrorCode = ErrorCode.PAYMENT_TIMEOUT;
        }, STRIPE_TIMEOUT_DELAY);

        await apolloClient
          .mutate({
            mutation: STRIPE_CREATE_CUSTOMER,
            variables: {
              workspaceId: this.currentWorkspace.id,
              billingInformation: { ...billingInformation, company: this.companyName }
            }
          })
          .catch(() => {
            clearTimeout(errorTimeout);
            this.paymentStatus = Status.FAILED;
            this.paymentErrorCode = ErrorCode.CREATE_CUSTOMER;
          });

        const subscriptionResult = await apolloClient
          .mutate({
            mutation: STRIPE_CREATE_SUBSCRIPTION,
            variables: {
              planVariantId: this.planVariant.id,
              billingPeriod: this.isYearly ? 'yearly' : 'monthly',
              billingCurrency: this.userCurrency,
              seats: getMembersSeats(this.currentWorkspace),
              workspaceId: this.workspaceId,
              storage: 60, // TODO : Unused by the back it'll be delete later
              coupon: this.isCouponValid ? this.coupon.trim() : null
            }
          })
          .catch(() => {
            clearTimeout(errorTimeout);
            this.paymentStatus = Status.FAILED;
            this.paymentErrorCode = ErrorCode.CREATE_SUBSCRIPTION;
          });

        // If price <= coupon then GraphQL API with Stripe directly creates the subscription
        // We just need to store the Payment Method
        /* if (this.getTotalPrice <= this.couponDiscount) { */
        clearTimeout(errorTimeout); // ??
        const intentResponse = await apolloClient.query({
          query: STRIPE_CREATE_SETUP_INTENT,
          variables: {
            workspaceId: this.currentWorkspace.id
          },
          error() {
            this.paymentStatus = Status.FAILED;
            this.paymentErrorCode = ErrorCode.CREATE_SETUP_INTENT;
          }
        });
        if (this.paymentStatus !== Status.PROCESSING) {
          return;
        }

        const secret = get(intentResponse, 'data.StripeCreateSetupIntent.secret');
        this.stripeInstance
          .confirmCardSetup(secret, {
            payment_method: {
              card: this.stripeCardElement,
              billing_details: billingInformation
            }
          })
          .then(result => {
            if (get(result, 'setupIntent.status') === 'succeeded') {
              this.paymentStatus = Status.SUCCESS;
              setTimeout(() => {
                this.$emit('onPaymentSuccess');
                this.setPaymentAsDefault({
                  subscriptionId: get(
                    subscriptionResult,
                    'data.StripeCreateSubscription.subscription_id'
                  ),
                  paymentMethodId: get(result, 'setupIntent.payment_method')
                });
              }, 2000);
            } else {
              this.paymentStatus = Status.FAILED;
              this.paymentErrorCode = ErrorCode.STRIPE_CREATE_PAYMENT_METHOD;
            }
          });
      }
    },
    async setPaymentAsDefault(variables) {
      await apolloClient.mutate({
        mutation: STRIPE_SET_DEFAULT_PAYMENT_METHOD,
        variables
      });
    },
    setCouponValue(event) {
      if (event.key === 'Enter') {
        this.submitCoupon();
        return;
      }
      const unTrimmedCoupon = event.target.value || '';
      this.coupon = unTrimmedCoupon.trim();
      this.isCouponValid = undefined;
      this.isCouponApplicable = undefined;
    },
    selectCountry(v) {
      this.country = v;
    },
    tryAgain() {
      this.paymentStatus = undefined;
    }
  }
};
</script>

<style lang="scss" scoped>
.form {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.leftPart {
  margin-right: 24px;
}

.rightPart {
  width: 393px;
  height: 100%;
}

.rightPartSticky {
  position: sticky;
  top: 0px;
  width: 393px;
}

fieldset {
  padding-bottom: 40px;
  margin-bottom: 40px;
  border-bottom: 1px solid $color_neutral_30;
  &:last-child {
    border: none;
  }
}

.formFooter {
  margin-top: 50px;
  font-size: 12px;
  color: $color_neutral_60;
  text-align: center;
  img {
    width: 100px;
    height: 22px;
    margin-bottom: 20px;
  }
}

.container {
  display: flex;
  flex-direction: row;
  width: 1000px;
  justify-content: space-between;
  margin: 0 auto;
}

.formContainer {
  padding-top: 80px;
}

.formOverlay {
  position: fixed;
  z-index: 1;
  background: rgba(37, 36, 40, 0.6);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.formProcessing {
  background: white;
  width: 500px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.formProcessingMessage {
  font-size: 16px;
  font-weight: 600;
  margin-top: 30px;
  color: $color_neutral_100;
}

.formErrorIcon {
  color: $color_danger_100;
  font-size: 32px;
}

.formErrorInformations {
  margin-top: 8px;
  font-size: 14px;
  margin-bottom: 20px;
}

.formErrorCode {
  color: $color_neutral_60;
  font-size: 14px;
  margin-bottom: 20px;
}

.retryButton {
  display: flex;
  justify-content: center;
}

.formTitle {
  font-size: 18px;
  font-weight: 600;
  line-height: 120%;
  padding-bottom: 24px;
  height: 40px;
  display: flex;
  align-items: center;
}

.formResume {
  border: 1px solid $color_neutral_30;
  border-radius: 10px;
  margin-bottom: 40px;
}

.stripeLabel {
  text-align: left;
  font-weight: 600;
  font-size: 12px;
  line-height: normal;
  letter-spacing: 0;
}

.StripeElement {
  background: white;
  border: 1px solid black;
  margin: 8px 0 20px;
  height: 46px;
  font-family: inherit;
  padding: 12px 12px;
  box-sizing: border-box;
  color: $color_neutral_100;
  font-size: 14px;
  outline: none;
  border: none;
  filter: none;
  font-weight: 400;
  border: 1px solid $color_neutral_40;
  border-radius: 4px;
  line-height: 46px;
  &.focused {
    border-color: black;
  }
}

.StripeElement--invalid {
  background: $color_danger_10;
  border-color: $color_danger_100;
  color: $color_danger_100;
}

.inputLine {
  margin-bottom: 24px;
}

.multiInputLine {
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 1080px) {
    flex-direction: column;
  }
  & > * {
    flex: 1;
    &:first-child {
      margin-right: 24px;
      @media screen and (max-width: 1080px) {
        margin-right: 0;
        margin-bottom: 24px;
      }
    }
  }
}

input[type='submit'] {
  width: 100%;
  cursor: pointer;
  background: $color_neutral_100;
  color: $color_neutral_0;
  height: 48px;
  text-align: center;
  border-radius: 4px;
  border: none;
  font-size: 16px;
  font-weight: 600;
  transition: background-color 200ms linear;
  &:hover {
    background-color: $color_neutral_80;
  }
  &:focus {
    box-shadow: 0 0 0 2px $color_neutral_40;
  }

  &.submitDisabled {
    background-color: $color_neutral_100;
    opacity: 0.4;
    cursor: not-allowed;
  }
}

.planInfosHeader,
.planInfosBody,
.planInfosFooter {
  align-items: center;
  display: flex;
  box-sizing: border-box;
  padding: 16px 24px;
  font-size: 14px;
}

.planInfosHeader {
  border-radius: 10px 10px 0 0;
  background: $color_neutral_5;
  height: 56px;
  border-bottom: 1px solid $color_neutral_30;
  font-weight: bold;

  i {
    background: $color_primary_10;
    color: $color_primary_100;
    align-items: center;
    width: 24px;
    height: 24px;
    border-radius: 30px;
    font-size: 14px;
    font-weight: 500;
    margin-right: 8px;
    display: inline-flex;
    justify-content: center;
    transform: rotate(-10deg);
  }
}

.planInfosBody {
  min-height: 56px;
  justify-content: space-between;
}

.planInfosRow {
  width: 100%;
}

.planCoupon {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1px solid $color_neutral_30;
  border-top: 1px solid $color_neutral_30;

  .couponLabel {
    padding-top: 13px;
    min-width: 100px;
    flex: 1;
  }

  .couponInput {
    padding: 0 12px;
    box-sizing: border-box;
    height: 40px;
    color: $color_neutral_100;
    font-size: 14px;
    outline: none;
    filter: none;
    font-weight: 400;
    width: 150px;
    border-radius: 4px;
    border: 1px solid $color_neutral_40;
    margin-right: 8px;
  }

  .invalidCouponInput {
    background: $color_danger_10;
    border-color: $color_danger_100;
  }

  .invalidCouponLabel {
    color: $color_danger_100;
    font-size: 12px;
    margin-top: 8px;
  }

  .couponSubmit {
    padding: 0 12px !important;
  }
}

.planValidCoupon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.couponGift {
  color: $color_neutral_100;
  border: 1px solid $color_neutral_30;
  background: $color_neutral_10;
  border-radius: 50px;
  height: 32px;
  display: flex;
  align-items: center;
  padding: 0 12px;

  i {
    margin-right: 10px;
    color: $color_neutral_60;
  }
}

.planDiscount {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.planDiscountPrice {
  font-size: 16px;
  font-weight: 600;
  color: green;
}

.planInfosFooter {
  height: 64px;
  justify-content: space-between;
}

.planInfosTotalPrice {
  font-size: 16px;
  font-weight: 600;
}
</style>
<style>
.couponSubmit.button.primary.medium.no-icon {
  width: 80px;
}
</style>
