<template>
  <div
    :class="{
      bridgeMarkdownEditor: true,
      hideToolbar: this.isEditorActive === false
    }"
    v-click-outside="{ hide: handleClickOutside }"
    @mousedown="handleMouseDown"
    @mouseup="handleMouseUp"
  >
    <editor
      ref="editor"
      initialEditType="wysiwyg"
      height="auto"
      :initialValue="editorInitialValue"
      :options="editorInitialOptions"
      @caretChange="handleChange"
      @change="handleChange"
      @focus="handleFocus"
    />
  </div>
</template>

<script>
import { Editor } from '@toast-ui/vue-editor';

import { clickOutside } from '@/utils/directives';

export default {
  components: {
    editor: Editor
  },
  directives: {
    clickOutside
  },
  props: {
    value: {
      type: String,
      required: false,
      default: null
    },
    fieldModel: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
      isEditorActive: false,
      isLoaded: false,
      canCloseOnClickOutside: true
    };
  },
  computed: {
    editorInitialValue() {
      return this.value || '';
    },
    editorInitialOptions() {
      return {
        autofocus: false,
        useCommandShortcut: true,
        usageStatistics: true,
        hideModeSwitch: true,
        toolbarItems: [['heading'], ['bold', 'italic', 'strike'], ['ul', 'ol'], ['link']],
        placeholder: 'Click here to add a description…',
        linkAttributes: 'target',
        minHeight: '40px'
      };
    }
  },
  beforeDestroy() {
    this.handleClickOutside();
  },
  methods: {
    handleMouseUp() {
      this.canCloseOnClickOutside = true;
    },
    handleMouseDown() {
      this.canCloseOnClickOutside = false;
    },
    handleChange() {
      // Force the toolbar to be visible in case of errors
      if (this.isLoaded) {
        this.isEditorActive = true;
      } else {
        // On load the editor emit a "CaretChange" event that will focus the editor.
        // We don't want that so we canCloseOnClickOutside the first event
        this.isLoaded = true;
      }
    },
    handleClickOutside() {
      if (this.isEditorActive && this.canCloseOnClickOutside === true) {
        this.isEditorActive = false;
        const markdown = this.$refs.editor.invoke('getMarkdown');
        const cleanedMarkdown = markdown.replaceAll('<br>', '').trim();
        // console.log('cleanedMarkdown', cleanedMarkdown);
        // Reset the markdown only if it's full of spaces or <br>
        if (cleanedMarkdown.length === 0 && markdown.length !== 0) {
          this.$refs.editor.invoke('setMarkdown', '');
          // Remove the focus during the animation
          setTimeout(() => {
            this.isEditorActive = false;
            this.$refs.editor.invoke('blur');
          }, 100);
        }

        // to solve this issue https://github.com/nhn/tui.editor/issues/1096
        // here we want to add 'https://' before each link if there is no 'http://' or 'https://'
        // so we're using two regexp:
        // first we add 'https://' before every link (minus images) in the string
        // if there are 2 protocols we replace any 'https://http(s)://' by 'user protocol' to handle the case were user enter the full url
        const doubleHttpMarkdown = cleanedMarkdown.replaceAll(
          /[(^!)]?\[(.*)\]\((.*)\)/gm,
          '[$1](https://$2)'
        );
        const filteredProjectDescription = doubleHttpMarkdown
          .replaceAll(/https:\/\/(https?):\/\//gm, '$1://')
          .replaceAll(/(\w)\\.(\w)/gm, '$1.$2');
        this.isEditorActive = false;
        this.$emit('update:fieldModel', filteredProjectDescription);
        this.$emit('validate');
      }
      this.canCloseOnClickOutside = true;
    },
    handleFocus() {
      this.isEditorActive = true;
    }
  }
};
</script>

<style src="@toast-ui/editor/dist/toastui-editor.css" />

<style lang="scss">
.hideToolbar {
  .toastui-editor-defaultUI {
    z-index: 1;
    border: 1px solid $color_neutral_0 !important;
    &:hover {
      border: 1px solid $color_neutral_30 !important;
      cursor: pointer;
    }
  }

  .toastui-editor-toolbar {
    height: 0 !important;
    transition: height 0.4s 0.1s ease !important;
  }

  .toastui-editor-toolbar-group {
    opacity: 0 !important;
    transition: opacity 0.4s ease !important;
  }
}

.bridgeMarkdownEditor {
  .toastui-editor-defaultUI {
    transition: border 0.4s ease;
    border: 1px solid $color_neutral_100;
    padding: 0;
    z-index: 10;
  }

  .ProseMirror {
    color: $color_neutral_100;
    padding: 18px 12px !important;
  }

  .toastui-editor-toolbar {
    height: 45px;
    transition: height 0.4s ease;
    box-sizing: border-box;

    & [data-level='4'],
    & [data-level='5'],
    & [data-level='6'] {
      display: none;
    }
  }

  .toastui-editor-popup {
    border-radius: 8px;
  }

  .toastui-editor-toolbar-group {
    transition: opacity 0.4s 0.1s ease;
    align-items: center;
    opacity: 1;
  }

  .toastui-editor-defaultUI-toolbar {
    background-color: $color_neutral_0;
    border-bottom: none;
    padding: 12px;
    display: flex;
    height: 30px;
    align-items: center;
    & button {
      border-radius: 4px;
      border: none;
      margin: 0 4px;
      &:hover {
        border: none;
        background-color: $color_primary_10;
      }
    }
  }

  .toastui-editor-toolbar-icons {
    background: url('./assets/sprite.svg') no-repeat;
    &:not(:disabled) {
      &.active {
        background-color: #2f43de;
        background-position-y: -64px !important;
      }
    }
    &:hover {
      background-position-y: -32px !important;
    }

    &.heading {
      background-position: 0px 0px !important;
    }
    &.bold {
      background-position: -32px 0 !important;
    }
    &.italic {
      background-position: -64px 0 !important;
    }
    &.strike {
      background-position: -96px 0 !important;
    }
    &.bullet-list {
      background-position: -128px 0 !important;
    }
    &.ordered-list {
      background-position: -160px 0 !important;
    }
    &.link {
      background-position: -192px 0 !important;
    }
  }

  .toastui-editor-popup-add-heading ul {
    padding: 0;
    & li {
      padding: 9.5px 16px;
      border-bottom: solid 1px $color_neutral_30;
      &:last-child {
        border-bottom: none;
      }
      &:hover {
        background-color: $color_neutral_30;
      }
    }
  }

  .toastui-editor-toolbar-divider {
    width: 1px;
    height: 16px;
    background-color: $color_neutral_30;
    margin: 14px 12px;
  }

  .toastui-editor-tooltip {
    background-color: $color_neutral_100;
    color: $color_neutral_0;
    border-radius: 4px;
    padding: 8px;
  }
  .toastui-editor-tooltip .arrow {
    background-color: $color_neutral_100;
  }

  .toastui-editor-ww-container {
    background: none;
  }
  .toastui-editor-main-container {
    color: $color_neutral_100;
  }

  .toastui-editor-contents {
    font-family: 'manrope', 'sans-serif' !important;
    font-size: 14px;

    ::-moz-selection {
      background: rgba(71, 35, 173, 0.1);
    }

    ::selection {
      background: rgba(71, 35, 173, 0.1);
    }

    h1 {
      font-size: 24px;
      font-weight: 600;
      line-height: 30px;
      letter-spacing: -0.02em;
      border-bottom: none;
      padding-bottom: 10px;
    }

    h2 {
      font-size: 18px;
      font-weight: 600;
      line-height: 23px;
      letter-spacing: -0.02em;
      border-bottom: none;
      padding-bottom: 10px;
    }

    h3 {
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0em;
    }

    strong {
      font-weight: bold;
    }

    del {
      background-color: none;
      color: inherit;
    }

    p {
      margin: 0;
    }
    a {
      color: #2f43de;
    }
  }

  .toastui-editor-popup-body input[type='text']:focus {
    border-color: black;
    outline: black;
  }

  .toastui-editor-close-button {
    background: $color_neutral_0;
    border-radius: 4px;
    font-weight: bold;
    border: none;
    &:hover {
      background: $color_neutral_10;
    }
  }

  .toastui-editor-ok-button {
    border-radius: 4px;
    font-weight: bold;
    background: $color_neutral_100;
    &:hover {
      background: $color_neutral_80;
    }
    &:focus {
      outline: $color_neutral_100;
    }
  }
}
</style>
